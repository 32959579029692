import { render, staticRenderFns } from "./cowWeightGainAgeGroupMonth.vue?vue&type=template&id=45de584d&scoped=true&"
import script from "./cowWeightGainAgeGroupMonth.vue?vue&type=script&lang=js&"
export * from "./cowWeightGainAgeGroupMonth.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45de584d",
  null
  
)

export default component.exports