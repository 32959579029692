import { render, staticRenderFns } from "./livestockSummaryFormat2.vue?vue&type=template&id=3017a50b&scoped=true&"
import script from "./livestockSummaryFormat2.vue?vue&type=script&lang=js&"
export * from "./livestockSummaryFormat2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3017a50b",
  null
  
)

export default component.exports